import { useAuth0 } from '@auth0/auth0-react'
import { useGetUserByEmail } from '../../operations/queries/getUserByEmail'
import { useContext, useEffect, useState } from 'react'
import { changeLanguage } from 'i18next'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import 'moment/locale/de'
import 'moment/locale/da'
import 'moment/locale/fo'
import 'moment/locale/en-gb'
import {
  OrganisationList,
  useGetOrganisationList,
} from '../../operations/queries/getOrganisationList'
import GlobalContext, { GlobalContextProps } from './GlobalContext'
import { exception } from 'src/lib/applicationinsights'
import { getNavItems, RolesType } from '../navItems'

// Define a type guard function to check if a value is of RolesType
const isRolesType = (value: unknown): value is RolesType => {
  return (
    typeof value === 'string' && ['developer', 'supplier', 'organisation_manager'].includes(value)
  )
}

export default function GlobalProvider({ children }) {
  const { user: authUser } = useAuth0()
  const authRole: RolesType | undefined = isRolesType(authUser['https://nemlia.com/roles'])
    ? authUser['https://nemlia.com/roles']
    : undefined

  const [currentOrganisation, setCurrentOrganisation] = useState<OrganisationList[0] | undefined>(
    undefined
  )

  const {
    data: uData,
    loading: uLoading,
    error: uError,
    refetch: uRefetch,
  } = useGetUserByEmail({ email: authUser.email })

  const {
    data: oData,
    loading: oLoading,
    error: oError,
    refetch: oRefetch,
  } = useGetOrganisationList()

  const user = uData?.ta_user[0]
  const organisations = oData?.ta_organisation

  const tz = currentOrganisation?.tz || user?.user_timezone || momentTZ.tz.guess()

  // Set default timezone
  momentTZ.tz.setDefault(tz)

  // Change global moment lng when user lng is updated
  useEffect(() => {
    if (!user) {
      return
    }

    const momentLocaleConfig = {
      week: {
        dow: 0, // Sunday is the first day of the week for representation
      },
    }

    const momentLng = (user.user_language === 'dk' ? 'da' : user.user_language) ?? 'en'
    moment.updateLocale(momentLng, momentLocaleConfig)
    momentTZ.updateLocale(momentLng, moment.localeData()['_config']) // copy locale to moment-timezone

    changeLanguage(user.user_language)
  }, [user])

  // Set initial organisationId
  useEffect(() => {
    if (oLoading) {
      return
    } else if (!organisations || !organisations.length || oError) {
      setCurrentOrganisation(undefined)
      localStorage.removeItem('currentOrganisationId')
    } else {
      const storageOrgId = localStorage.getItem('currentOrganisationId')
      const organisation = organisations?.find((o) => o.id === storageOrgId) || organisations[0]

      setCurrentOrganisation(organisation)
      localStorage.setItem('currentOrganisationId', organisation.id)
    }
  }, [oError, oLoading, organisations])

  // Error tracking
  if (uError) {
    exception('Styra initial page load: Error getting user', {
      msg: uError.message,
    })
  }
  if (oError) {
    exception('Styra initial page load: Error getting organisationList', {
      msg: oError.message,
    })
  }

  // Actions
  const onOrganisationChange = (id: string) => {
    setCurrentOrganisation(organisations?.find((o) => o.id === id))
    localStorage.setItem('currentOrganisationId', id)
  }

  const contextProps: GlobalContextProps = {
    user: user
      ? {
          ...user,
          user_language: user?.user_language || 'en',
        }
      : undefined,
    organisations: organisations || [],
    currentOrganisation: currentOrganisation,
    setCurrentOrganisation: onOrganisationChange,
    refetch: {
      user: uRefetch,
      organisations: oRefetch,
    },
    pageLoading: Boolean(uLoading || oLoading),
    pageError: Boolean(uError || oError || !user),
    role: authRole,
    isManager: authRole === 'organisation_manager',
    navItems: getNavItems(authRole),
  }

  return <GlobalContext.Provider value={contextProps}>{children}</GlobalContext.Provider>
}

export function useGlobal() {
  const context = useContext(GlobalContext)
  if (context === undefined) {
    throw new Error('useItems must be used within a ItemsProvider')
  }
  return context
}
