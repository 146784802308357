import { alpha, lighten, createTheme, darken } from '@mui/material/styles'
import { getBaseColors, getIconPath } from './utils'
import { SvgIconComponent } from '@mui/icons-material'

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Chip' {
  interface ChipOwnProps {
    rounded?: boolean
    disableUserSelect?: boolean
  }
  interface ChipPropsVariantOverrides {
    inverted: true
    text: true
  }
}

declare module '@mui/material/Switch' {
  interface SwitchProps {
    width?: number
    height?: number
    startIcon?: SvgIconComponent
    endIcon?: SvgIconComponent
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxProps {
    disablePadding?: boolean
  }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    90?: string
    80?: string
    70?: string
    60?: string
    50?: string
    40?: string
    30?: string
    20?: string
    10?: string
    5?: string
  }

  interface SimplePaletteColorOptions {
    90?: string
    80?: string
    70?: string
    60?: string
    50?: string
    40?: string
    30?: string
    20?: string
    10?: string
    5?: string
    900?: string
    800?: string
    700?: string
    600?: string
    500?: string
    400?: string
    300?: string
    200?: string
    100?: string
  }
}

// Config
export const config = {
  drawerWidth: 220,
  headerHeight: 60,
}

// Base colors
export const baseColors = {
  primary: '#0061fe', // Example color: #2b58fd
  secondary: '#ebebeb',
  success: '#4caf50',
  error: '#ef5350',
  warning: { main: '#ff9800', dark: '#f57c00' },
}

// Create a theme instance.
const mainTheme = createTheme({
  palette: {
    ...getBaseColors(baseColors),
    info: {
      main: '#007BFF',
    },
    neutral: {
      main: '#AEAEB2',
      dark: '#76767A',
      light: '#F2F2F7',
      90: alpha('#AEAEB2', 0.9),
      80: alpha('#AEAEB2', 0.8),
      70: alpha('#AEAEB2', 0.7),
      60: alpha('#AEAEB2', 0.6),
      50: alpha('#AEAEB2', 0.5),
      40: alpha('#AEAEB2', 0.4),
      30: alpha('#AEAEB2', 0.3),
      20: alpha('#AEAEB2', 0.2),
      10: alpha('#AEAEB2', 0.1),
      5: alpha('#AEAEB2', 0.05),
      900: lighten('#AEAEB2', 0.1),
      800: lighten('#AEAEB2', 0.18),
      700: lighten('#AEAEB2', 0.28),
      600: lighten('#AEAEB2', 0.37),
      500: lighten('#AEAEB2', 0.46),
      400: lighten('#AEAEB2', 0.55),
      300: lighten('#AEAEB2', 0.64),
      200: lighten('#AEAEB2', 0.73),
      100: lighten('#AEAEB2', 0.82),
    },
  },
  typography: {
    fontSize: 12.25,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCheckbox: {
      variants: [{ props: { disablePadding: true }, style: { padding: 0, borderRadius: 0 } }],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 23,
          fontSize: 11,
          paddingLeft: 8,
          paddingRight: 8,
          gap: 6,
          '& .MuiChip-label': {
            padding: 0,
          },
          '& .MuiChip-deleteIcon:hover': {
            color: 'inherit',
            filter: 'brightness(0.9)',
          },
          '& .MuiChip-icon, .MuiChip-deleteIcon': {
            marginLeft: -3,
            marginRight: -3,
            color: 'inherit',
          },
          '& .MuiChip-iconMedium, .MuiChip-deleteIconMedium': {
            fontSize: 17,
          },
          '& .MuiChip-iconSmall, .MuiChip-deleteIconSmall': {
            fontSize: 14,
          },
          variants: [
            {
              props: { variant: 'filled' },
              style: ({ ownerState, theme }) => {
                const color = theme.palette[ownerState?.color]?.main || theme.palette.neutral.dark
                return {
                  color: darken(color, 0.1),
                  backgroundColor: alpha(color, 0.2),
                  '&&.MuiChip-clickable:hover': {
                    backgroundColor: alpha(color, 0.3),
                  },
                }
              },
            },
            {
              props: { size: 'small' },
              style: {
                height: 19,
                fontSize: 10,
                paddingLeft: 6,
                paddingRight: 6,
              },
            },
            {
              props: { variant: 'text' },
              style: ({ ownerState, theme }) => {
                const color = theme.palette[ownerState?.color]?.main || theme.palette.neutral.dark

                return {
                  height: 'auto',
                  paddingLeft: 0,
                  paddingRight: 0,
                  border: 'none',
                  gap: 3,
                  backgroundColor: 'transparent',
                  color: color,
                  '&&.MuiChip-clickable:hover': {
                    backgroundColor: 'transparent',
                    color: darken(color, 0.2),
                  },
                  '& .MuiChip-deleteIcon:hover': {
                    color: 'inherit',
                  },
                  '& .MuiChip-icon, .MuiChip-deleteIcon': {
                    marginLeft: 0,
                    marginRight: 0,
                    color: 'inherit',
                  },
                }
              },
            },
            {
              props: { rounded: true },
              style: { borderRadius: 999 },
            },
            {
              props: { disableUserSelect: true },
              style: { userSelect: 'none' },
            },
          ],
        },
      },
    },
    MuiSwitch: {
      variants: [
        {
          props: { width: 200 },
          style: {},
        },
      ],
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          const { width = 36, height = 18, startIcon, endIcon } = ownerState
          const thumbSize = height * 0.76
          const thumbCenterValue = (height - thumbSize) / 2
          const iconSize = height * 0.7
          const iconCenterValue = (height - iconSize) / 2 + 1

          const startIconBackground = startIcon
            ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${iconSize}" width="${iconSize}" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
              )}" d="${getIconPath(startIcon)}"/></svg>')`
            : ''

          const endIconBackground = endIcon
            ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${iconSize}" width="${iconSize}" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
              )}" d="${getIconPath(endIcon)}"/></svg>')`
            : ''

          return {
            cursor: 'pointer',
            padding: 0,
            width: width,
            height: height,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              '.MuiSwitch-input': {
                width: width + height,
                left: 0,
              },
              '&.Mui-checked': {
                transform: `translateX(${width - height}px)`,
                '.MuiSwitch-input': {
                  left: -width,
                },
              },
            },
            '& .MuiSwitch-track': {
              borderRadius: height / 2,
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: iconSize,
                height: iconSize,
              },
              '&::before': startIcon
                ? {
                    left: iconCenterValue,
                    backgroundImage: startIconBackground,
                  }
                : undefined,
              '&::after': endIcon
                ? {
                    right: iconCenterValue,
                    backgroundImage: endIconBackground,
                  }
                : undefined,
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: thumbSize,
              height: thumbSize,
              margin: thumbCenterValue,
            },
          }
        },
      },
    },
  },
})

export default mainTheme
