import { SvgIconComponent } from '@mui/icons-material'
import { common } from '@mui/material/colors'
import { alpha, lighten, darken } from '@mui/material/styles'
import { createElement } from 'react'
import ReactDOMServer from 'react-dom/server'

type Color = string | { main: string; dark?: string }
type BaseColors = { [key: string]: Color }

// Function to extract the main color
const getMainColor = (color: Color) => {
  if (typeof color === 'string') {
    return color
  } else if (color.main) {
    return color.main
  }
  return null // Handle invalid input
}

// Generate tint colors for all base colors
export const getBaseColors = (baseColors: BaseColors) =>
  Object.keys(baseColors).reduce((result, key) => {
    const mainColor = getMainColor(baseColors[key])
    if (!mainColor) {
      return result
    }

    result[key] = {
      dark: baseColors[key]['dark'] || darken(mainColor, 0.2),
      main: mainColor,
      contrastText: common.white,
      90: alpha(mainColor, 0.9),
      80: alpha(mainColor, 0.8),
      70: alpha(mainColor, 0.7),
      60: alpha(mainColor, 0.6),
      50: alpha(mainColor, 0.5),
      40: alpha(mainColor, 0.4),
      30: alpha(mainColor, 0.3),
      20: alpha(mainColor, 0.2),
      10: alpha(mainColor, 0.1),
      5: alpha(mainColor, 0.05),
      900: lighten(mainColor, 0.1),
      800: lighten(mainColor, 0.18),
      700: lighten(mainColor, 0.28),
      600: lighten(mainColor, 0.37),
      500: lighten(mainColor, 0.46),
      400: lighten(mainColor, 0.55),
      300: lighten(mainColor, 0.64),
      200: lighten(mainColor, 0.73),
      100: lighten(mainColor, 0.82),
    }
    return result
  }, {})

export const getIconPath = (IconComponent: SvgIconComponent) => {
  const iconElement = createElement(IconComponent)
  const iconString = ReactDOMServer.renderToString(iconElement)
  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(iconString, 'image/svg+xml')
  const iconPath = svgDoc.querySelector('path')?.getAttribute('d') as string

  return iconPath
}
