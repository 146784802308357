export function apiUrl() {
  console.log('configuring for ', process.env.NEXT_PUBLIC_API_URL)
  return process.env.NEXT_PUBLIC_API_URL
}

export function hasuraUrl() {
  return process.env.NEXT_PUBLIC_HASURA_URL
}

export function hasuraWssUrl() {
  return hasuraUrl().replace(/^http/, 'ws')
}

export const colorArray = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
]

export const isNumberOfDaysAgo = (days: number, date: string): boolean => {
  const current = new Date().getTime() - days * 24 * 60 * 60 * 1000

  const dateTime = new Date(date).getTime()
  if (isNaN(dateTime) || current > dateTime) {
    return true
  }
  return false
}

export function safeParseJSON<T>(data: string, def: T): T {
  try {
    return JSON.parse(data)
  } catch (e) {
    return def
  }
}

export const arraysEqual = <T>(a: T[], b: T[]): boolean => {
  return a.every((item) => b.includes(item)) && b.every((item) => a.includes(item))
}

export const toggleStringArray = (str: string, array: string[]) =>
  array.includes(str) ? array.filter((v) => v !== str) : [...array, str]

export const addUniqueStringToArray = (array: string[], string?: string) =>
  string && !array.includes(string) ? [...array, string] : array
